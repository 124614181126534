import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["departmentInput", "input", "divToHide", "checkbox"]

  connect() {
    const departements = []
    fetch("https://geo.api.gouv.fr/departements?zone=metro,drom,com")
        .then(response => response.json())
        .then((data) => {
          data.forEach((departement) => {
            departements.push(`${departement["code"]} - ${departement["nom"]}`)
          })
        })
        .then(() => {
          let departementOptions = ""
          departements.forEach((departement) => {
            departementOptions = departementOptions.concat("\n", `<option value="${departement}"></option>`);
          })
          return departementOptions;
        })
        .then((departementOptions) => {
          const inputDepartementSelection = `

        <input class="departement-selection" disabled list="departement-selection" placeholder="01 - Ain" type="text" name="general_doctor[gd_department]" id="general_doctor_gd_department" , data-action="mousedown->general-doctors#resetSelect mouseup->general-doctors#resetFocus", data-general-doctors-target="allInputs"}>
          <datalist id="departement-selection">
            ${departementOptions}
          </datalist>
      `;
          this.departmentInputTarget.insertAdjacentHTML("beforeend", inputDepartementSelection)
        })

        this.checkboxTargets.forEach((checkbox) => {
          if (checkbox.checked) {
            this.divToHideTargets.find(el => el.dataset.id == checkbox.dataset.id).classList.remove('hide')
          }
        })
  }

  toggleInput(event) {
    const id = event.currentTarget.dataset.id;
    const checkbox = this.checkboxTargets.filter(el => el.dataset.id == id)[0];
    const divToHide = this.divToHideTargets.filter(el => el.dataset.id == id)[0].classList
    
    if(checkbox.checked) {
      divToHide.remove('hide')
    } else {
      divToHide.add('hide')
      this.inputTargets.filter(input => input.dataset.id == id).forEach((input) => {
        input.type == "radio" ? input.checked = false : input.value = "";
      })
    }
  }

  resetSelect(e) {
    e.target.value = ""
  }

  resetFocus(e) {
    e.target.focus()
  }
}
