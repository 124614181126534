import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["errors", "resolved"]

  errorsTargetConnected(element) {
    element.closest(".questionnaire-section").classList.add("warning");
  }

  resolvedTargetConnected(element) {
    element.closest(".questionnaire-section").classList.remove("warning")
  }
}
