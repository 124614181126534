import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => this.count(e));
    this.count()
  }

  count(e) {
    let count = 0;
    let questionnaireSections = Array.from(document.getElementsByClassName('questionnaire-section'));
    questionnaireSections.forEach((section) => {
      let radioButton = section.getElementsByClassName('radio_button_true')[0];
      if (radioButton.checked) {
        let questionPointCount = section.getElementsByClassName('question-point-value')[0];
        let questionPointCountValue = questionPointCount.innerHTML;
        let questionPointCountValueInt = parseInt(questionPointCountValue);
        count += questionPointCountValueInt;
      }
    });
    const counterOut = document.getElementById('counter-out');
    counterOut.innerHTML = count.toString();
  }
}
