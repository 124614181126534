import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["input", "errors", "resolved"]

  connect() {
    const departements = []
    fetch("https://geo.api.gouv.fr/departements?zone=metro,drom,com")
        .then(response => response.json())
        .then((data) => {
          data.forEach((departement) => {
            departements.push(`${departement["code"]} - ${departement["nom"]}`)
          })
        })
        .then(() => {
          let departementOptions = ""
          departements.forEach((departement) => {
            departementOptions = departementOptions.concat("\n", `<option value="${departement}"></option>`);
          })
          return departementOptions;
        })
        .then((departementOptions) => {
          const inputDepartementSelection = `

        <input class="departement-selection" list="departement-selection" placeholder="01 - Ain" type="text" name="general_patient[q06_department]" id="general_patient_q06_department" , data-action="mousedown->general-patients#resetSelect mouseup->general-patients#resetFocus blur->general-patients#validate"}>
          <datalist id="departement-selection">
            ${departementOptions}
          </datalist>
      `;
          this.inputTarget.insertAdjacentHTML("beforeend", inputDepartementSelection)
        })
  }

  errorsTargetConnected(element) {
    element.closest(".questionnaire-section").classList.add("warning");
  }

  resolvedTargetConnected(element) {
    element.closest(".questionnaire-section").classList.remove("warning")
  }

  validate(event) {
    if (event.target.value < 0) {
      // event.target.closest(".questionnaire-section").classList.add("warning");
      // const text = document.createTextNode("Veuillez selectionner un valeur égale ou supérieur à 0");
      event.target.closest(".questionnaire-answer").parentElement.querySelector(".error-messages").appendChild(text)
    } else {
      // event.target.closest(".questionnaire-section").classList.remove("warning");
      event.target.closest(".questionnaire-answer").parentElement.querySelector(".error-messages").textContent = ""
    }
  }

  askDosage(event) {
    event.target.closest(".medication-question").querySelectorAll(".dosage-question").forEach((e) => {
      e.classList.remove("hide")
    })
  }

  removeDosage(event) {
    event.target.closest(".medication-question").querySelectorAll(".dosage-question").forEach((e) => {
      e.classList.add("hide")
    })
  }

  askOtherDiagnoses(event) {
    event.target.closest(".questionnaire-section").querySelectorAll(".other-diagnoses").forEach((e) => {
      e.classList.remove("hide")
    })
  }

  removeOtherDiagnoses(event) {
    event.target.closest(".questionnaire-section").querySelectorAll(".other-diagnoses").forEach((e) => {
      e.classList.add("hide")
    })
    document.getElementById("general_patient_q10_other").value = "";
  }

  resetSelect(e) {
    e.target.value = ""
  }

  resetFocus(e) {
    e.target.focus()
  }
}
