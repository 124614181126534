import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["patient", "doctor"]

  connect() {
    
  }

  reveal(e) {
    if(e.currentTarget.classList.contains("patient")) {
      this.patientTarget.style.opacity = "1"
      this.doctorTarget.style.opacity = "0.3";
    } else {
      this.doctorTarget.style.opacity = "1"
      this.patientTarget.style.opacity = "0.3";
    }
  }
}
