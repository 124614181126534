import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["text", "button"]

  toggle() {
    this.textTarget.classList.toggle("text-overflow");
  }

}
