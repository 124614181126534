import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "divToHide", "checkbox", "antinuclear", "valid", "dnaContainer", "dnaLevel"]

  connect() {

    const departements = []
    fetch("https://geo.api.gouv.fr/departements?zone=metro,drom,com")
        .then(response => response.json())
        .then((data) => {
          data.forEach((departement) => {
            departements.push(`${departement["code"]} - ${departement["nom"]}`)
          })
        })
        .then(() => {
          let departementOptions = ""
          departements.forEach((departement) => {
            departementOptions = departementOptions.concat("\n", `<option value="${departement}"></option>`);
          })
          return departementOptions;
        })
        .then((departementOptions) => {
          const inputDepartementSelection = `

        <input class="departement-selection" list="departement-selection" placeholder="01 - Ain" type="text" name="general_doctor[gd_department]" id="general_doctor_gd_department" , data-action="mousedown->general-doctors#resetSelect mouseup->general-doctors#resetFocus", data-general-doctors-target="allInputs"}>
          <datalist id="departement-selection">
            ${departementOptions}
          </datalist>
      `;
          this.inputTarget.insertAdjacentHTML("beforeend", inputDepartementSelection)
        })

        this.checkboxTargets.forEach((checkbox) => {
          if (checkbox.checked) {
            this.divToHideTargets.find(el => el.dataset.id == checkbox.dataset.id).classList.remove('hide')
          }
        })
  }

  toggleInput(event) {
    const id = event.currentTarget.dataset.id;
    const checkbox = this.checkboxTargets.filter(el => el.dataset.id == id)[0];
    if(checkbox.checked) {
      this.divToHideTargets.filter(el => el.dataset.id == id)[0].classList.remove('hide')
    } else {
      this.divToHideTargets.filter(el => el.dataset.id == id)[0].classList.add('hide')
      this.inputTargets.filter(input => input.dataset.id == id).forEach((input) => {
        input.type == "radio" ? input.checked = false : input.value = "";
      })
    }
  }

  checkInput() {
    if(this.antinuclearTarget.value === "" || this.antinuclearTarget.value == null) {
      return this.validTarget.value = "false"
    } else if(!this.dnaContainerTarget.classList.contains('hide')) {
      if(this.dnaLevelTarget.value === "" || this.dnaLevelTarget.value === "") return this.validTarget.value = "false"
    }

    return this.validTarget.value = "true"
  }

  resetSelect(e) {
    e.target.value = ""
  }

  resetFocus(e) {
    e.target.focus()
  }

  pushOtherToArray(event) {

    const others = document.getElementsByClassName("complication-grid")[0]
    const otherCheckboxes = others.getElementsByClassName("others-section")

    let lastCheckbox = otherCheckboxes[otherCheckboxes.length - 1]
    if (event.currentTarget == lastCheckbox) {
            const checkboxes = Array.from(otherCheckboxes);
            for (let i = 0; i < checkboxes.length - 1; i++) {
              checkboxes[i].classList.toggle('disabled-checkbox');
              checkboxes[i].children[0].checked = false;
            }


    }
  }



}
