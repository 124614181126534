import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ["modal", "continue", "text"]

  connect() {

  }

  copy_code() {
    navigator.clipboard.writeText(this.textTarget.innerHTML)
    Swal.fire({
      width: '400px',
      position: 'bottom',
      icon: 'success',
      iconColor: "#662F94",
      title: "Code copié : " + this.textTarget.innerHTML,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'sweetalert-title'
      }
    })
  }

  toggleModal() {
    this.modalTarget.classList.toggle("hide");
  }

  changeValue() {
    this.continueTarget.value = "true"
  }
}
