import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["checkbox"]

  connect() {

    const checkboxes = this.checkboxTargets
    const checkboxNone = checkboxes[3]
    this.element.addEventListener('click', (e) => {

      if (e.target.parentElement == checkboxNone) {
        let alreadyActive = !checkboxNone.classList.contains('active-other')
        checkboxes.forEach((checkbox) => {
          if (!alreadyActive) {
            checkbox.classList.remove("active-other")
            // if checkbox is not the "none" checkbox
            if (checkbox != checkboxNone) {
              checkbox.classList.add("disabled-other")
            } else {
              checkbox.classList.add("active-other")
            }
          } else {
            checkbox.classList.remove("disabled-other")
            checkbox.classList.remove("active-other")
          }
        })
      }
    })

  }


}
