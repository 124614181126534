import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="health-questionnaire"
export default class extends Controller {

  static targets = ["output", "clockSelect", "outputBodySymptoms", "otherOutput", "bodySymptomsModal", "bodySymptomsModalValue", "errors", "resolved", "checkbox"]

  connect() {
    // Puts a listener on the entire document to listen for a turbo fetch request
    document.addEventListener("turbo:before-fetch-request", async (event) => {
      // If the turbo fetch requet is an "body_symptoms" action, it will execute
      if (/.*body_symptoms.*/.test(event.target.action)) {
        const bodySymptomsValueParam = new URLSearchParams(
            // The string will be passed into the params, which will be accessible in the turbo frame as "@link"
            `body_symptoms=${this.outputBodySymptomsTarget.value}`
        );
        event.detail.fetchOptions.body = bodySymptomsValueParam;
      }
    });
  }

  updateSliderValue(event) {
    event.currentTarget.setAttribute("value", event.currentTarget.value);
  }

  select(event) {
    // start with putting all clocks unselected
    this.unselectAllClocks()
    // add class cl-active to the current pressed node
    event.currentTarget.classList.toggle('active-clock')

    let value = event.params.time
    console.log('valeur clock',value)
    if (typeof value === "string" && value.includes("h")) {
      value = value + "+"
      console.log('valeur clock updated',value)
    }
    // update the target (in fact the attribute value of the textfield in the form)
    this.outputTarget.setAttribute('value', value)
  }



  unselectAllClocks() {
    this.clockSelectTargets.forEach((element, index) => {
      element.classList.remove("active-clock")
    })
  }

  pushToArray(event) {
    // On click, adds or removes class to/from body part path
    event.currentTarget.classList.toggle('active-body-part')
    // Define string, takes the current list of selected body parts
    let string = this.outputBodySymptomsTarget.value
    // Add/remove clicked body part name to/from list of selected body parts
    if (string.split(", ").includes(event.params.bodypart)) {
      string = string.replace(event.params.bodypart, "")
    } else {
      if (string.length > 1) {
        string = string.concat(', ', event.params.bodypart)
      } else {
        string = event.params.bodypart
      }
    }
    // Inject updated list as value back into the the input
    this.outputBodySymptomsTarget.setAttribute('value', string)
  }

  pushOtherToArray(event) {
    if (!event.currentTarget.classList.contains('disabled-other')) {
      event.currentTarget.classList.toggle('active-other')
      let capturedString = this.otherOutputTarget.value
      if (capturedString.split(", ").includes(event.params.check)) {
        capturedString = capturedString.replace(event.params.check, "")
      } else {
        if (capturedString.length > 1) {
          capturedString = capturedString.concat(', ', event.params.check)
        } else {
          capturedString = event.params.check
        }
      }

      this.otherOutputTarget.setAttribute('value', capturedString)
    }

    this.otherOutputTarget.setAttribute('value', this.otherOutputTarget.value.replace(/, ,/g, ','))

    const others = document.getElementsByClassName("questionnaire-answers-others")[0]
    const otherCheckboxes = others.getElementsByClassName("others-section")


    let lastCheckbox = otherCheckboxes[otherCheckboxes.length - 1]
    if (event.currentTarget == lastCheckbox) {
        if (!lastCheckbox.classList.contains('active-other')) {
          this.otherOutputTarget.setAttribute('value', "")
        }
        else {
          this.otherOutputTarget.setAttribute('value', "no_0")
        }

    }
  }

  addActiveToPaths(string) {
    // Parse list into an array, so that we can iterate on each item of list
    const array = string.split(", ");
    // Iterate on list
    array.forEach((bodypart) => {
      // For each list item, we select all paths with the same class
      const parts = Array.from(document.getElementsByClassName(bodypart));
      // For each path with that class, we add the "active" class
      parts.forEach((part) => {
        part.classList.add("active-body-part");
      });
    });
  }

// This function adds "active" to required body parts in the modal
  bodySymptomsSvgTargetConnected() {
    // Once the body symptoms modal (turbo frame) is rendered, below is executed
    // Define string, takes the current list of selected body parts
    const string = this.bodySymptomsModalTarget.dataset.list;
    if (string !== "") {
      this.addActiveToPaths(string);
    }
  }

// This function is called when the submit button on the modal is clicked
  close() {
    // Hide the modal
    this.bodySymptomsModalTarget.classList.toggle("hide");
    // Define current list of selected body parts
    let string = this.outputBodySymptomsTarget.value;
    this.addActiveToPaths(string);
    // Parse into array all paths that were previously selected
    const activeParts = Array.from(document.getElementsByClassName("active-body-part"));
    activeParts.forEach((part) => {
      // For each path, find all of its classes (to determine which part it is)
      const partClasses = Array.from(part.classList);
      // If any of those classes are NOT included in the list of selected body parts, execute action
      if (!partClasses.some((r) => string.split(", ").includes(r))) {
        // Remove "active" from the svg on the initial page
        part.classList.remove("active-body-part");
      }
    });
  }

  errorsTargetConnected(element) {
    element.closest(".questionnaire-section").classList.add("warning");
  }

  resolvedTargetConnected(element) {
    element.closest(".questionnaire-section").classList.remove("warning")
  }
}
