import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  update(event) {
    const scoreField = document.querySelector(".rating-select")
    let starClicked = event.target.dataset.value

    document.querySelectorAll(".star").forEach((star) => {
      // empty path for empty stars
      let emptyStarPath = document.getElementById("star-empty").src;
      let fullStarPath = document.getElementById("star-full").src;
      let starValue = parseInt(star.classList[1].split("-")[1])
      if (starValue <= starClicked) {
        star.src = fullStarPath
      } else {
        star.src = emptyStarPath
      }

    });
    scoreField.value = starClicked.toString()
  }

}
